export type Price = {
    currency?: ECurrency;
    includingVat: number;
    excludingVat: number;
};

export enum EDeliveryOption {
    MAIN = 'COMPANY_ADDRESS',
    HOME = 'HOME_ADDRESS',
}

export enum ECountry {
    SE = 'SE',
    DE = 'DE',
    NO = 'NO',
    UK = 'UK',
    AT = 'AT',
}

export enum ELocale {
    SV = 'sv-SE',
    EN = 'en-GB',
    NO = 'nb-NO',
    DE = 'de-DE',
    MASTER = 'master',
}

export enum ELanguage {
    'sv-SE' = 'Svenska',
    'en-GB' = 'English',
    'nb-NO' = 'Norsk',
    'de-DE' = 'Deutsch',
    'master' = 'Master',
}

export enum ECurrency {
    EUR = 'EUR',
    SEK = 'SEK',
    NOK = 'NOK',
    GBP = 'GBP',
}

export enum ButtonVariant {
    PRIMARY = 'primary',
    SECONDARY = 'secondary',
    TEXT = 'text',
}

export enum ButtonSize {
    DEFAULT = 'default',
    SMALL = 'small',
}

export enum ButtonType {
    SUBMIT = 'submit',
    BUTTON = 'button',
    RESET = 'reset',
}

export enum ESortingOrder {
    ASC = 'ASC',
    DESC = 'DESC',
}

export type ChangeRecord = {
    changeTime: Date | string;
    from: string;
    to: string;
};

export type Address = {
    id?: string;
    country: string;
    region?: string;
    city: string;
    postalCode: string;
    addressLine1: string;
    addressLine2?: string;
};

export enum EToastType {
    ERROR = 'error',
    SUCCESS = 'success',
    INFO = 'info',
}

export enum FileTypes {
    CSV = 'text/csv',
    XLSX = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
}

export type PaginatedResponse = {
    isLastPage: boolean;
    numberOfElements: number;
    pageNumber: number;
    pageSize: number;
    totalNumberOfElements: number;
    totalNumberOfPages: number;
};

export type BlobFile = {
    file: Blob;
    fileName: string;
    isLoading?: boolean;
};

export type Contact = {
    id?: string;
    firstName?: string;
    lastName?: string;
    email?: string;
    phoneNumber?: string;
    countryCode?: string;
};

export type DropDownType = {
    text: string;
    value: string;
};

export enum EPageErrorType {
    TEMPORARY = 'temporary',
    BROKEN_URL = 'brokenUrl',
    CRITICAL_ERROR_NO_MENU = 'critical_error_no_menu',
    TEMPORARY_ERROR_NO_MENU = 'temporary_error_no_menu',
    NO_PERMISSION = 'no_permission',
    BROKEN_INVITATION_URL = 'broken_invitation_url',
}

export interface NotificationsConnection {
    url: string;
    accessToken: string;
}

export enum EExportFileType {
    EXCEL = 'EXCEL',
    PDF = 'PDF',
}

export enum FEATURE_LIST {
    FACILITY_MANAGEMENT = 'SHOW_FACILITY_MANAGEMENT',
    SHOW_ONBOARD_GUIDE = 'SHOW_ONBOARD_GUIDE',
    INVOICES = 'SHOW_INVOICES',
    SIGNALR = 'USE_SIGNALR',
    SHOW_FEATURE_FOR_QA = 'SHOW_FEATURE_FOR_QA',
}
