import FileValidationField from './FileValidationField';
import Button from 'components/clickables/Button';
import NotificationMessage from 'components/info/NotificationMessage';
import BrowseFile from 'components/widgets/BrowseFile';
import { PATH } from 'constants/paths';
import { useModal } from 'contexts/Modal';
import { useAddress } from 'hooks/useAddress';
import useBusinesses from 'hooks/useBusinesses';
import { useCountrySpecificContent } from 'hooks/useCountrySpecificContent';
import useGetBusiness from 'hooks/useGetBusiness';
import useLocalState from 'hooks/useLocalState';
import useSubscriptionFileReader from 'hooks/useSubscriptionFileReader';
import { JSX, useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { darkerGrey, font, spaceL, spaceM, spaceS, spaceXs } from 'styles/variables';
import { EBusinessFeature } from 'types/business';
import { ETrackingAction, ETrackingEvent } from 'types/tracking';
import validateBulkSubscription from 'utils/subscription/validateBulkSubscription';

const ModalContainer = styled.div`
    padding: ${spaceM};
`;

const Title = styled.h1`
    font-family: ${font.bold};
    font-weight: ${font.weight.bold};
    font-size: ${font.size.xl};
    line-height: ${spaceL};
    margin-bottom: ${spaceM};
`;

const FieldOrderContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 3fr;
    gap: ${spaceXs};
    margin-top: ${spaceM};
    line-height: ${spaceM};
    line-height: ${font.lineHeight.m};
    color: ${darkerGrey};
    font-size: ${font.size.s};
    h2 {
        font-weight: ${font.weight.bold};
        font-size: inherit;
    }
    p {
        line-height: inherit;
        margin: 0;
        span {
            display: block;
            &:last-child {
                margin-top: ${spaceS};
            }
        }
    }
`;

const SelectedFile = styled.h2`
    font-weight: ${font.weight.bold};
    font-size: ${font.size.s};
    margin-top: ${spaceM};
    color: ${darkerGrey};
`;

const ImportButton = styled(Button)`
    margin-top: ${spaceM};
    width: 100%;
    display: flex;
    justify-content: center;
`;

const NotificationWrapper = styled.div`
    margin-bottom: ${spaceS};
`;

function ImportFileModal(): JSX.Element {
    const { t } = useTranslation();
    const { businesses, error } = useBusinesses();
    const { getActiveBusiness, getBusinessesListByFeature, getBusinessByName } = useGetBusiness(businesses);
    const { validateAddress } = useAddress();

    const allowedBusinessesByFeature = getBusinessesListByFeature([EBusinessFeature.SUBSCRIPTION_MANAGEMENT_EDIT]).map(
        (business) => business.text,
    );

    const [subscriptionError, setSubscriptionError] = useState<string | null>(null);
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [clearFile, setClearFile] = useState(false);
    const { error: readerError, result } = useSubscriptionFileReader(selectedFile);
    const navigate = useNavigate();
    const { close } = useModal();
    const [isImporting, setIsimporting] = useState(false);

    const {
        actions: { setBulkImportSubscriptionOrder, removeAllBulkImportSubscriptionOrder },
        state: { bulkImportSubscriptionOrder },
    } = useLocalState();

    const handleOnClick = (): void => {
        if (selectedFile) {
            setClearFile(true);
            setSelectedFile(null);
            setSubscriptionError(null);
            setClearFile(false);
        }
    };

    const handleFileImport = (): void => {
        ReactGA.event(ETrackingEvent.IMPORT_SUBSCRIPTIONS, {
            action: ETrackingAction.IMPORT,
        });
        setIsimporting(true);
    };

    const { licencePlateRegExp } = useCountrySpecificContent();

    useEffect(() => {
        if (isImporting) {
            if (subscriptionError || readerError) {
                removeAllBulkImportSubscriptionOrder();
            } else if (!error) {
                const validatedData = validateBulkSubscription({
                    subscriptionArray: result,
                    validateAddress,
                    activeBusiness: getActiveBusiness(),
                    allowedBusinessesByFeature,
                    businesses,
                    getBusinessByName,
                    licencePlateRegExp,
                });
                const localStorageReadySubscriptionData = validatedData.map((subscription, idx) => {
                    return { ...subscription, row: idx };
                });
                if (localStorageReadySubscriptionData) {
                    setBulkImportSubscriptionOrder(localStorageReadySubscriptionData);
                }

                navigate(PATH.SUBSCRIPTION_IMPORT);
            } else {
                navigate(PATH.ERROR);
            }
            close();
        }
    }, [
        allowedBusinessesByFeature,
        businesses,
        close,
        error,
        getActiveBusiness,
        getBusinessByName,
        isImporting,
        navigate,
        result,
        setBulkImportSubscriptionOrder,
        validateAddress,
        readerError,
        removeAllBulkImportSubscriptionOrder,
        subscriptionError,
        licencePlateRegExp,
    ]);

    return (
        <ModalContainer>
            <Title>{t('widget.importFileModal.title')}</Title>
            {bulkImportSubscriptionOrder.length ? (
                <NotificationWrapper>
                    <NotificationMessage
                        message={t('widget.importFileModal.alreadyImportedSubscriptions')}
                        showTitle={false}
                    />
                </NotificationWrapper>
            ) : null}
            <BrowseFile clearFile={clearFile} setError={setSubscriptionError} setSelectedFile={setSelectedFile} />
            {selectedFile ? (
                <>
                    <SelectedFile>{t('widget.importFileModal.selectedFile')}</SelectedFile>
                    <FileValidationField
                        fileName={selectedFile.name}
                        errorMessage={subscriptionError ?? readerError ?? ''}
                        handleClick={handleOnClick}
                    />
                    <ImportButton isLoading={isImporting} onClick={handleFileImport}>
                        {t('widget.importFileModal.title')}
                    </ImportButton>
                </>
            ) : null}
            <FieldOrderContainer>
                <h2>{t('widget.importFileModal.fieldOrder')}</h2>
                <p>
                    <span> {t('widget.importFileModal.columnValues')}</span>
                    <span> {t('widget.browseFile.description')}</span>
                </p>
            </FieldOrderContainer>
        </ModalContainer>
    );
}

export default ImportFileModal;
