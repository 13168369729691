import SubscriptionOrderDetailsPanel from '../../components/subscriptions/subscriptionDetails/SubscriptionOrderDetailsPanel';
import SubscriptionOrderStatusPopUp from '../../components/subscriptions/SubscriptionOrderStatusPopUp';
import StatusPill from 'components/info/StatusPill';
import TextEllipsisComponent, { TextEllipsis } from 'components/info/TextEllipsisComponent';
import Page from 'components/layout/Page';
import SidePanel from 'components/layout/SidePanel';
import GridList, { GridListHeader, GridListItem, GridListItemButton, Header } from 'components/lists/GridList';
import ListFooter from 'components/lists/ListFooter';
import SortableColumnHeader from 'components/lists/SortableColumnHeader';
import { PAGE_SIZE } from 'constants/general';
import { pageKeys } from 'constants/pageKeys';
import useBusinessId from 'hooks/useBusinessId';
import { useDate } from 'hooks/useDate';
import useSubscriptionOrders from 'hooks/useSubscriptionOrders';
import { t } from 'i18next';
import { JSX, useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { darkGrey, spaceXs } from 'styles/variables';
import { ESortingOrder } from 'types';
import { ESubscriptionSortingColumns, SubscriptionOrder } from 'types/subscription';
import { ETrackingOrigin } from 'types/tracking';

const gridColumn = '9.5rem minmax(3rem, 1fr) 7rem minmax(3rem, 1fr) 10.5rem minmax(3rem, 1fr)';

const StyledTextEllipsis = styled(TextEllipsis)`
    color: ${darkGrey};
`;

const StyledHeader = styled(Header)`
    display: flex;
    gap: ${spaceXs};
    align-items: center;
`;

function SubscriptionOrdersPage(): JSX.Element {
    const sidePanelRef = useRef<HTMLDivElement>(null); // Ref for the sidebar
    const productListRef = useRef<HTMLDivElement>(null); // Ref for the product list
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOrder, setSelectedOrder] = useState<SubscriptionOrder>();

    const { activeParentBusinessId } = useBusinessId();
    const [searchParams, setSearchParams] = useSearchParams();
    const { formatDate } = useDate();

    const querySearchParam = searchParams;

    const [sorting, setSorting] = useState({
        sortColumn: ESubscriptionSortingColumns.CREATION_TIME,
        sortDirection: ESortingOrder.DESC,
    });

    const searchPageNumber = searchParams.get('pageNumber');

    const [pageNumber, setPageNumber] = useState<number>(searchPageNumber ? Number(searchPageNumber) : 1);

    useEffect(() => {
        if (pageNumber === 1) {
            querySearchParam.set('pageNumber', '1');
        } else {
            querySearchParam.set('pageNumber', String(pageNumber));
        }

        setSearchParams(querySearchParam);
    }, [pageNumber, querySearchParam, setSearchParams]);

    const subscriptionsQueryData = {
        businessId: activeParentBusinessId,
        pageNumber: pageNumber - 1,
        pageSize: PAGE_SIZE,
        sortColumn: sorting.sortColumn,
        sortDirection: sorting.sortDirection,
    };

    const {
        subscriptionOrderList,
        error: errorSubscriptions,
        isLoading,
        mutateSubscriptionOrders,
    } = useSubscriptionOrders(subscriptionsQueryData);

    const haveSubscriptions = subscriptionOrderList && subscriptionOrderList.subscriptionOrders.length >= 1;

    const handlePagination = (page: number): void => {
        setPageNumber(page);
    };

    const getSortingDirection = (column: ESubscriptionSortingColumns): ESortingOrder => {
        const direction = sorting.sortColumn === column ? sorting.sortDirection : undefined;
        return direction as ESortingOrder;
    };

    const handleOnSortChange = (sortColumn: ESubscriptionSortingColumns, sortDirection: ESortingOrder): void => {
        setSorting({ sortColumn, sortDirection });
    };

    const openPanel = (ord: SubscriptionOrder, event: React.MouseEvent): void => {
        event.stopPropagation();
        setSelectedOrder(ord);
        setIsOpen(true);
    };

    const closePanel = (): void => {
        setIsOpen(false);
    };

    useEffect(() => {
        function handler(e: MouseEvent): void {
            const target = e.target as HTMLElement;
            const closestUl = target.closest('ul');

            if (
                !sidePanelRef?.current?.contains(e.target as Node) &&
                target.getAttribute('data-testid') !== 'languageSelector' &&
                closestUl?.getAttribute('data-testid') !== 'languageList'
            ) {
                const closestLi = target.closest('li');
                if (productListRef.current && closestLi) {
                    const parentList = closestLi.parentElement;

                    if (parentList) {
                        const allListItems = Array.from(parentList.children);
                        const index = allListItems.indexOf(closestLi);

                        if (index === 0) {
                            setSelectedOrder(undefined);
                            closePanel();
                        }
                    }
                } else if (!productListRef.current?.contains(e.target as Node)) {
                    setSelectedOrder(undefined);
                    closePanel();
                }
            }
        }

        document.addEventListener('mousedown', handler);
        return () => {
            document.removeEventListener('mousedown', handler);
        };
    }, [sidePanelRef]);

    return (
        <>
            <Page title={t('subscription.orders.title')} pageKey={pageKeys.SUBSCRIPTION_ORDER}>
                <GridList
                    ref={productListRef}
                    clickable
                    gridColumns={gridColumn}
                    dataTestId="subscriptionsOrderList"
                    showEmptyListMessage={subscriptionOrderList?.subscriptionOrders.length === 0 && !isLoading}
                    isLoading={!haveSubscriptions && isLoading}
                    hasError={!!errorSubscriptions}
                >
                    <GridListHeader>
                        <StyledHeader>
                            {t('general.status')}
                            <SubscriptionOrderStatusPopUp gaTrackingOrigin={ETrackingOrigin.SUBSCRIPTION_ORDER} />
                        </StyledHeader>
                        <Header>{t('general.name')}</Header>
                        <Header>{t('general.licencePlate')}</Header>
                        <Header>{t('general.reference')}</Header>
                        <SortableColumnHeader
                            sortingDirection={getSortingDirection(ESubscriptionSortingColumns.CREATION_TIME)}
                            onSortChange={(direction) =>
                                handleOnSortChange(
                                    ESubscriptionSortingColumns.CREATION_TIME,
                                    direction as ESortingOrder,
                                )
                            }
                            text={t('general.creationDate')}
                        />
                        <Header>{t('general.subscription')}</Header>
                    </GridListHeader>
                    {haveSubscriptions && !errorSubscriptions && (
                        <>
                            {subscriptionOrderList.subscriptionOrders.map((order) => (
                                <GridListItem
                                    key={order.id}
                                    className={selectedOrder?.id === order.id ? 'selected' : ''}
                                >
                                    <GridListItemButton
                                        data-testid={`listItemButton_${order.id}`}
                                        onClick={(event) => openPanel(order, event)}
                                    >
                                        {order.status ? <StatusPill status={order.status} /> : <span />}
                                        {order.unknownDriver ? (
                                            <StyledTextEllipsis data-testid="nonPersonalOrder">
                                                {t('subscription.nonPersonal')}
                                            </StyledTextEllipsis>
                                        ) : (
                                            <TextEllipsisComponent
                                                dataTestId="personalOrder"
                                                tooltipText={`${order.driver?.firstName} ${order.driver?.lastName}`}
                                            >{`${order.driver?.firstName} ${order.driver?.lastName}`}</TextEllipsisComponent>
                                        )}
                                        <TextEllipsis>{order.licencePlate}</TextEllipsis>
                                        <TextEllipsisComponent tooltipText={order.reference}>
                                            {order.reference}
                                        </TextEllipsisComponent>
                                        <p>{formatDate(order.creationTime)}</p>
                                        <TextEllipsisComponent tooltipText={order.name}>
                                            {order.name}
                                        </TextEllipsisComponent>
                                    </GridListItemButton>
                                </GridListItem>
                            ))}
                        </>
                    )}
                </GridList>
                {haveSubscriptions && (
                    <ListFooter
                        data={subscriptionOrderList}
                        handlePagination={handlePagination}
                        pageNumber={pageNumber}
                    />
                )}
            </Page>
            <SidePanel
                isOpen={isOpen}
                onClose={closePanel}
                title={t('subscription.details.orderTitle')}
                ref={sidePanelRef}
            >
                {selectedOrder && (
                    <SubscriptionOrderDetailsPanel
                        selectedOrder={selectedOrder}
                        sidePanelOpen={isOpen}
                        onSave={() => mutateSubscriptionOrders()}
                    />
                )}
            </SidePanel>
        </>
    );
}

export default SubscriptionOrdersPage;
