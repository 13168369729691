import facilityImage from 'assets/images/img_facility.jpg';
import faqImage from 'assets/images/img_faq.jpg';
import Banner from 'components/banner/Banner';
import Button from 'components/clickables/Button';
import CustomerServiceBox from 'components/startpage/CustomerServiceBox';
import LargeContentBox from 'components/startpage/LargeContentBox';
import OnboardGuide from 'components/startpage/OnboardGuide';
import SectionContainer from 'components/startpage/SectionContainer';
import SmallContentBox from 'components/startpage/SmallContentBox';
import ActiveSubscriptionsKpi from 'components/subscriptions/statistics/ActiveSubscriptionsKpi';
import Co2Kpi from 'components/subscriptions/statistics/Co2Kpi';
import InvoiceInfo from 'components/subscriptions/statistics/InvoiceInfo';
import { pageKeys } from 'constants/pageKeys';
import { PATH } from 'constants/paths';
import SubscriptionStatisticsContext from 'contexts/SubscriptionStatisticsContext';
import useAuthentication from 'hooks/useAuthentication';
import useAuthorization from 'hooks/useAuthorization';
import useBUContent from 'hooks/useBUContent';
import useBusinesses from 'hooks/useBusinesses';
import useGetBusiness from 'hooks/useGetBusiness';
import { JSX, useEffect } from 'react';
import ReactGA from 'react-ga4';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { font, maxWidthContent, spaceM, spaceXs } from 'styles/variables';
import { EBusinessFeature } from 'types/business';
import { ETrackingEvent, ETrackingOrigin } from 'types/tracking';

const Page = styled.div`
    max-width: ${maxWidthContent};

    .subscriptionKPISection {
        container-type: inline-size;
        container-name: kpi-container;
        max-width: 50rem;
    }
`;

const Header = styled.h1`
    font-size: ${font.size.m};
    font-weight: ${font.weight.regular};
    color: ${font.color.grey};
    line-height: ${font.lineHeight.l};
    margin-bottom: 32px;
`;

const ContactSection = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: ${spaceM};
    max-width: 100%;
`;

const Link = styled.a`
    display: block;
    width: fit-content;
    text-decoration: none;
    margin-bottom: ${spaceXs};
    word-break: break-all;
`;

const KpiSection = styled.div`
    display: flex;
    gap: ${spaceM};
    flex-wrap: wrap;

    @container kpi-container (max-width: 500px) {
        display: flex;
        flex-direction: column;
    }

    > * {
        flex: 1;
        min-width: 12.5rem;
        max-width: 24rem;
        @container kpi-container (max-width: 500px) {
            min-width: 100%;
        }
    }
`;

function Startpage(): JSX.Element {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { buContent } = useBUContent();
    const { getAccountName } = useAuthentication();
    const name = getAccountName();

    const {
        checkAuthorizationFeatureArray,
        error: errorAuthorization,
        isLoading: isLoadingAuthorization,
    } = useAuthorization();

    useEffect(() => {
        if (!isLoadingAuthorization && errorAuthorization) {
            if (errorAuthorization.temporary) {
                navigate(PATH.TEMPORARY_ERROR_NO_MENU);
            } else {
                navigate(PATH.CRITICAL_ERROR_NO_MENU);
            }
        }
    }, [errorAuthorization, isLoadingAuthorization, navigate]);

    const haveFacilityFeature = checkAuthorizationFeatureArray([
        EBusinessFeature.FACILITY_MANAGEMENT_EDIT,
        EBusinessFeature.FACILITY_MANAGEMENT_VIEW,
    ]);

    const { businesses } = useBusinesses();
    const { getBusinessesListByFeature } = useGetBusiness(businesses);

    const organisationList = getBusinessesListByFeature([
        EBusinessFeature.SUBSCRIPTION_MANAGEMENT_EDIT,
        EBusinessFeature.SUBSCRIPTION_MANAGEMENT_VIEW,
    ]);

    const parentBusinessId = organisationList.length > 0 ? `${organisationList[0].value}_all` : undefined;

    return (
        <>
            <Banner pageKey={pageKeys.STARTPAGE} />
            <Page data-testid="startpage">
                <Header>
                    <Trans
                        i18nKey="startpage.greeting"
                        values={{
                            userName: name,
                        }}
                    />
                </Header>
                <OnboardGuide />
                {parentBusinessId && (
                    <SectionContainer
                        title={t('startpage.subscriptionSectionTitle')}
                        extraInfo={<InvoiceInfo origin={ETrackingOrigin.STARTPAGE} />}
                        className="subscriptionKPISection"
                        dataTestId="subscriptionKPISection"
                    >
                        <KpiSection>
                            <SubscriptionStatisticsContext value={parentBusinessId}>
                                <ActiveSubscriptionsKpi />
                                <Co2Kpi />
                            </SubscriptionStatisticsContext>
                        </KpiSection>
                    </SectionContainer>
                )}
                {haveFacilityFeature && (
                    <SectionContainer title={t('startpage.facilitySection.title')} dataTestId="facilitySection">
                        <LargeContentBox
                            title={t('startpage.facilitySection.subtitle')}
                            text={t('startpage.facilitySection.description')}
                            image={facilityImage}
                        >
                            <Button
                                onClick={() => navigate(PATH.FACILITY_STATISTICS)}
                                dataTestId="facilitySectionButton"
                            >
                                {t('startpage.facilitySection.buttonText')}
                            </Button>
                        </LargeContentBox>
                    </SectionContainer>
                )}
                <SectionContainer title={t('startpage.helpSectionTitle')} className="helpSection">
                    <ContactSection>
                        <SmallContentBox
                            dataTestId="faqHelpBox"
                            title={t('startpage.faq.title')}
                            text={t('startpage.faq.description')}
                            image={faqImage}
                        >
                            <Link
                                href={`${buContent.faqLink}`}
                                onMouseDown={() => {
                                    ReactGA.event(ETrackingEvent.FAQ, { origin: ETrackingOrigin.STARTPAGE });
                                }}
                            >
                                {t('startpage.faq.linkText')}
                            </Link>
                        </SmallContentBox>
                        <CustomerServiceBox buContent={buContent} />
                    </ContactSection>
                </SectionContainer>
            </Page>
        </>
    );
}
export default Startpage;
