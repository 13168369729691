import { yupResolver } from '@hookform/resolvers/yup';
import InfoIcon from 'assets/icons/ic_24_info.svg?react';
import QuestionMarkTooltipIcon from 'assets/icons/question_mark_32.svg?react';
import ApiError from 'classes/ApiError';
import Button from 'components/clickables/Button';
import InfoIconButton from 'components/clickables/InfoIconButton';
import LinkText from 'components/clickables/LinkText';
import DropdownInput from 'components/forms/DropdownInput';
import Form from 'components/forms/Form';
import RadioButton from 'components/forms/RadioButton';
import TextInput from 'components/forms/TextInput';
import CustomerServiceInfoBox from 'components/info/CustomerServiceInfoBox';
import DeliveryOptionsInfo from 'components/info/DeliveryOptionsInfo';
import InfoBox from 'components/info/InfoBox';
import NotificationMessage, { EInfoType } from 'components/info/NotificationMessage';
import PopUp from 'components/info/PopUp';
import TooltipContent from 'components/info/TooltipContent';
import Page from 'components/layout/Page';
import SubscriptionTypesInfo from 'components/subscriptions/SubscriptionTypesInfo';
import { EMAIL_REGEXP, MAX_LENGTH_EMAIL, MAX_LENGTH_NAME } from 'constants/general';
import { EMPTY_NONPERSONAL_SUBSCRIPTION, EMPTY_PERSONAL_SUBSCRIPTION } from 'constants/initialData';
import { pageKeys } from 'constants/pageKeys';
import { useErrors } from 'contexts/ErrorContext';
import { useModal } from 'contexts/Modal';
import { useToast } from 'contexts/Toast';
import useBUContent from 'hooks/useBUContent';
import useBusinesses from 'hooks/useBusinesses';
import useContact from 'hooks/useContact';
import { useCountrySpecificContent } from 'hooks/useCountrySpecificContent';
import useGetBusiness from 'hooks/useGetBusiness';
import useSubscriptionOrder from 'hooks/useSubscriptionOrder';
import { useSubscriptionPlans } from 'hooks/useSubscriptionPlans';
import { ChangeEvent, JSX, useEffect, useMemo, useState } from 'react';
import ReactGA from 'react-ga4';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { font, licencePlateInputWidth, readingWidth, spaceL, spaceM, spaceS, spaceXs } from 'styles/variables';
import { ButtonType, DropDownType, EDeliveryOption, EToastType } from 'types';
import { EBusinessFeature, Organisation } from 'types/business';
import {
    CreateSubscriptionFormInputType,
    CreateSubscriptionOrderType,
    ESubscriptionFormType,
} from 'types/subscription';
import { ETrackingAction, ETrackingEvent, ETrackingOrigin, ETrackingType } from 'types/tracking';
import { scrollToTop } from 'utils/scrollToTop';
import * as yup from 'yup';

const Content = styled.div`
    display: grid;
    grid-template-columns: minmax(18.75rem, ${readingWidth}) minmax(12.5rem, 25rem);
    gap: 3.375rem;

    @container page-container (max-width: 40.625rem) {
        display: flex;
        flex-direction: column;
        gap: ${spaceS};
        max-width: ${readingWidth};
    }
`;

const StyledSpan = styled.span`
    display: block;
    margin-top: ${spaceS};
`;

const StyledForm = styled(Form)`
    display: flex;
    flex-direction: column;
    margin: ${spaceL} 0;
    max-width: ${readingWidth};
`;

const LicencePlateInput = styled(TextInput)`
    max-width: ${licencePlateInputWidth};
`;

const StyledButton = styled(Button)`
    align-self: flex-start;
    margin-top: ${spaceM};
`;

const PopUpWrapper = styled.div`
    display: flex;
    align-items: center;
`;

const SubHeader = styled.h2`
    font-weight: ${font.weight.semiBold};
    font-size: ${font.size.m};
`;

const StyledRadioButton = styled(RadioButton)`
    width: max-content;
`;

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    gap: ${spaceS};
    margin-bottom: ${spaceXs};
`;

const Aside = styled.aside`
    display: flex;
    flex-direction: column;
    gap: ${spaceS};
`;

const CreateSubscriptionSchema = (
    licencePlateRegExp: RegExp,
): yup.ObjectSchema<Omit<CreateSubscriptionFormInputType, 'organisation'>> =>
    yup.object().shape({
        id: yup.string(),
        type: yup.string().required('form.input.subscription.required'),
        unknownContact: yup.boolean().required(),
        contact: yup.object().when('unknownContact', {
            is: false,
            then: () =>
                yup.object().shape({
                    email: yup
                        .string()
                        .required('form.input.email.validation')
                        .matches(EMAIL_REGEXP, { message: 'form.input.email.validation' }),
                    firstName: yup.string().required('form.input.firstName.required'),
                    lastName: yup.string().required('form.input.lastName.required'),
                }),
        }),
        licencePlate: yup
            .string()
            .required('form.input.licencePlate.validation')
            .matches(licencePlateRegExp, { message: 'form.input.licencePlate.validation' }),
        reference: yup.string().required('form.input.reference.required'),
        deliveryOption: yup
            .mixed<EDeliveryOption>()
            .oneOf(Object.values(EDeliveryOption), 'form.input.deliveryOption.required')
            .required('form.input.deliveryOption.required'),
        organisationId: yup.string().required('form.input.organisation.required'),
    });

function CreateSubscriptionPage(): JSX.Element {
    const { t } = useTranslation();
    const { addToast } = useToast();
    const { errors: apiErrors, setErrors } = useErrors();
    const [subscriptionOrder, setSubscriptionOrder] =
        useState<CreateSubscriptionFormInputType>(EMPTY_PERSONAL_SUBSCRIPTION);
    const [selectedOrganisation, setSelectedOrganisation] = useState<Organisation | undefined>();
    const { businesses, error: errorBusinesses } = useBusinesses();
    const { getBusinessesListByFeature, getBusiness } = useGetBusiness(businesses);
    const [selectedRadio, setSelectedRadio] = useState(ESubscriptionFormType.PERSONAL);

    const organisationList = useMemo((): DropDownType[] | undefined => {
        return businesses ? getBusinessesListByFeature([EBusinessFeature.SUBSCRIPTION_MANAGEMENT_EDIT]) : undefined;
    }, [businesses, getBusinessesListByFeature]);

    const { buContent } = useBUContent();

    const { createSubscriptions, error: errorSubscription } = useSubscriptionOrder({
        businessId: selectedOrganisation?.id ?? '',
    });

    const { subscriptionPlans, isLoading: isLoadingSubscriptionPlans } = useSubscriptionPlans({
        businessId: selectedOrganisation?.id ?? '',
    });

    useEffect(() => {
        const errs: ApiError[] = [];
        if (errorSubscription) {
            errs.push(errorSubscription);
        }
        if (errorBusinesses) {
            errs.push(errorBusinesses);
        }
        setErrors(errs);
    }, [errorBusinesses, errorSubscription, setErrors]);

    const { licencePlateRegExp, licencePlateMinLength, licencePlateMaxLength, countryCode } =
        useCountrySpecificContent();

    const formMethods = useForm<CreateSubscriptionFormInputType>({
        mode: 'onBlur',
        resolver: yupResolver(CreateSubscriptionSchema(licencePlateRegExp)),
        shouldUnregister: false,
        shouldFocusError: true,
        defaultValues: {
            ...EMPTY_NONPERSONAL_SUBSCRIPTION,
        },
    });

    const {
        register,
        handleSubmit,
        formState: { errors },
        watch,
        reset,
        setValue,
        resetField,
    } = formMethods;

    const onSubscriptionTypeChange = (e: ChangeEvent<HTMLInputElement>): void => {
        if (e.currentTarget.value === ESubscriptionFormType.PERSONAL) {
            setSelectedRadio(ESubscriptionFormType.PERSONAL);
            setSubscriptionOrder({
                ...EMPTY_PERSONAL_SUBSCRIPTION,
                type: watch('type'),
                reference: watch('reference'),
                licencePlate: watch('licencePlate'),
            });
        } else {
            setSelectedRadio(ESubscriptionFormType.NONPERSONAL);
            setSubscriptionOrder({
                ...EMPTY_NONPERSONAL_SUBSCRIPTION,
                type: watch('type'),
                reference: watch('reference'),
                licencePlate: watch('licencePlate'),
            });
        }
    };

    const completeAddress =
        selectedOrganisation?.address?.addressLine1 &&
        selectedOrganisation?.address?.city &&
        selectedOrganisation?.address?.postalCode;
    const disabledDeliveryOption = selectedOrganisation && !completeAddress;

    const organisationId = watch('organisationId');
    const organisation = useMemo(
        () => (organisationId ? getBusiness(organisationId) : undefined),
        [getBusiness, organisationId],
    );

    useEffect(() => {
        resetField('type', { keepError: true });
        if (organisationId && businesses && organisation) {
            setSelectedOrganisation(organisation);
            if (selectedRadio === ESubscriptionFormType.NONPERSONAL) {
                if (!disabledDeliveryOption) {
                    setValue('deliveryOption', EDeliveryOption.MAIN, {
                        shouldValidate: true,
                    });
                } else {
                    setValue('deliveryOption', '', { shouldValidate: true });
                }
            }
        } else {
            setSelectedOrganisation(undefined);
            resetField('deliveryOption', { keepError: true });
        }
    }, [businesses, disabledDeliveryOption, organisation, organisationId, resetField, selectedRadio, setValue]);

    useEffect(() => {
        if (subscriptionOrder) {
            reset(subscriptionOrder);
        }
    }, [reset, subscriptionOrder]);

    const { open } = useModal();
    const showSubscriptionPlansInfo = (): void => {
        if (subscriptionPlans) {
            ReactGA.event(ETrackingEvent.SUBSCRIPTION_PLANS_INFO, {
                action: ETrackingAction.OPEN_MODAL,
                origin: ETrackingOrigin.CREATE_SUBSCRIPTION,
            });
            open(<SubscriptionTypesInfo subscriptionPlans={subscriptionPlans} />);
        }
    };

    const onSubmit = async (data: CreateSubscriptionFormInputType): Promise<void> => {
        reset();
        scrollToTop();
        addToast({ message: t('subscription.create.subscriptionSent'), type: EToastType.INFO });

        try {
            const subscriptionPlan = subscriptionPlans?.find((plan) => plan.id === data.type);

            const order: CreateSubscriptionOrderType = {
                ...(!!data.contact && {
                    contact: { ...data.contact, countryCode },
                }),
                campaignId: subscriptionPlan?.campaignId ?? '',
                licencePlate: data.licencePlate,
                reference: data.reference,
                productId: data.type,
                deliveryOption: data.deliveryOption,
                countryCode,
                shipToPostalAddressId:
                    data.deliveryOption === EDeliveryOption.MAIN ? organisation?.address?.id : undefined,
            };

            await createSubscriptions.mutateAsync(order);

            ReactGA.event(ETrackingEvent.CREATE_SUBSCRIPTIONS, {
                type: order.contact ? ETrackingType.PERSONALISED : ETrackingType.UNPERSONALISED,
            });
            addToast({ message: t('subscription.create.successToast'), type: EToastType.SUCCESS });
        } catch (e) {
            if (e instanceof ApiError) {
                if (e.status === 409) {
                    addToast({ message: t('subscription.create.conflictError'), type: EToastType.ERROR });
                } else if (e.temporary) {
                    addToast({ message: t('general.errorToast'), type: EToastType.ERROR });
                } else {
                    addToast({
                        message: t('subscription.create.createSubscriptionError'),
                        type: EToastType.ERROR,
                    });
                }
            }
        }
    };

    const [isEmailFieldBlurred, setIsEmailFieldBlurred] = useState<boolean>(false);

    const isEmailCorrect = EMAIL_REGEXP.test(watch('contact.email') ?? '');
    const email = isEmailCorrect && isEmailFieldBlurred ? watch('contact.email') : '';

    const { contact, error: contactError } = useContact(organisationId || '', email);

    const onBlur = (e: { target: { value: string | undefined } }): void => {
        setValue('contact.email', e.target.value, { shouldValidate: true });
        setIsEmailFieldBlurred(true);
    };

    const firstNameValue = watch('contact.firstName');
    const lastNameValue = watch('contact.lastName');
    const nameMask = '*****';
    useEffect(() => {
        if (contact) {
            const firstName = contact.firstName ?? nameMask;
            const lastName = contact.lastName ?? nameMask;
            setValue('contact.firstName', firstName, { shouldValidate: true });
            setValue('contact.lastName', lastName, { shouldValidate: true });
        } else {
            resetField('contact.firstName', { keepError: false });
            resetField('contact.lastName', { keepError: false });
        }
    }, [contact, setValue, resetField]);

    const disabledSubscriptionPlan =
        !selectedOrganisation || isLoadingSubscriptionPlans || !subscriptionPlans || subscriptionPlans.length === 0;

    const showEmailErrorInfo = contactError && contactError.status !== 404;

    const deliveryOptions = [
        {
            text: t(`deliveryOption.${EDeliveryOption.HOME}`),
            value: EDeliveryOption.HOME,
        },
    ];

    if (!disabledDeliveryOption) {
        deliveryOptions.unshift({
            text: `${selectedOrganisation?.name} - ${selectedOrganisation?.address?.addressLine1}, ${selectedOrganisation?.address?.postalCode} ${selectedOrganisation?.address?.city}`,
            value: EDeliveryOption.MAIN,
        });
    }

    return (
        <Page title={t('subscription.create.title')} errors={apiErrors} pageKey={pageKeys.CREATE_SUBSCRIPTION}>
            <Content>
                <div>
                    <Wrapper>
                        <SubHeader>{t('subscription.create.subscriptionType')}</SubHeader>
                        <PopUpWrapper>
                            <PopUp
                                dataTestId="subscriptionTypeInfo"
                                content={
                                    <TooltipContent
                                        headline={t('subscription.create.subscriptionTypesInfo.title')}
                                        text={t('subscription.create.subscriptionTypesInfo.description')}
                                        icon={<QuestionMarkTooltipIcon />}
                                    />
                                }
                            />
                        </PopUpWrapper>
                    </Wrapper>
                    <StyledRadioButton
                        dataTestId="radio_personal"
                        id={ESubscriptionFormType.PERSONAL}
                        name="subscriptionTypeRadioButtons"
                        onChange={onSubscriptionTypeChange}
                        label={t(`subscription.${ESubscriptionFormType.PERSONAL}`)}
                        value={ESubscriptionFormType.PERSONAL}
                        checked={selectedRadio === ESubscriptionFormType.PERSONAL}
                    />
                    <StyledRadioButton
                        dataTestId="radio_non_personal"
                        id={ESubscriptionFormType.NONPERSONAL}
                        name="subscriptionTypeRadioButtons"
                        onChange={onSubscriptionTypeChange}
                        label={t(`subscription.${ESubscriptionFormType.NONPERSONAL}`)}
                        value={ESubscriptionFormType.NONPERSONAL}
                        checked={selectedRadio === ESubscriptionFormType.NONPERSONAL}
                    />
                    <StyledForm formMethods={formMethods} onSubmit={handleSubmit(onSubmit)}>
                        <DropdownInput
                            dataTestId="createSubscriptionFormOrganisation"
                            label={t('form.input.organisation.label')}
                            options={organisationList}
                            fieldError={errors.organisationId}
                            required
                            {...register('organisationId')}
                            customPlaceholderOption="form.input.organisation.placeHolder"
                        />
                        <DropdownInput
                            dataTestId="createSubscriptionFormPlan"
                            label={t('form.input.subscription.label')}
                            options={subscriptionPlans?.map((plan) => ({
                                value: plan.id,
                                text: plan.offer?.name || plan.name,
                            }))}
                            fieldError={errors.type}
                            required
                            infoButton={
                                <InfoIconButton
                                    disabled={disabledSubscriptionPlan}
                                    dataTestId="subscriptionPlanInfoButton"
                                    onClick={showSubscriptionPlansInfo}
                                />
                            }
                            customPlaceholderOption="form.input.subscription.placeHolder"
                            infoMessage={
                                !!selectedOrganisation && !isLoadingSubscriptionPlans && disabledSubscriptionPlan ? (
                                    <NotificationMessage
                                        type={EInfoType.ERROR}
                                        dataTestId="createSubscriptionFormPlanDisabled"
                                        showTitle={false}
                                        message={
                                            <Trans
                                                i18nKey="subscription.create.subscriptionPlanErrorMessage"
                                                values={{
                                                    mailAddress: buContent.customerService.email,
                                                    phoneNumber: buContent.customerService.phoneNumer,
                                                }}
                                                components={{
                                                    mailto: (
                                                        <LinkText to={`mailto:${buContent.customerService.email}`} />
                                                    ),
                                                }}
                                            />
                                        }
                                    />
                                ) : null
                            }
                            {...register('type', { required: true })}
                            disabled={disabledSubscriptionPlan}
                            isLoading={isLoadingSubscriptionPlans}
                        />
                        <TextInput
                            dataTestId="createSubscriptionFormReference"
                            maxLength={25}
                            required
                            label={t('form.input.reference.label')}
                            fieldError={errors.reference}
                            {...register('reference', { required: true })}
                        />
                        <LicencePlateInput
                            dataTestId="createSubscriptionFormLicencePlate"
                            displayErrorTextOnOneLine
                            maxLength={licencePlateMaxLength}
                            minLength={licencePlateMinLength}
                            label={t('form.input.licencePlate.label')}
                            required
                            fieldError={errors.licencePlate}
                            {...register('licencePlate', { required: true })}
                        />
                        {selectedRadio === ESubscriptionFormType.PERSONAL && (
                            <>
                                <TextInput
                                    dataTestId="createSubscriptionFormFirstName"
                                    label={t('form.input.firstName.label')}
                                    maxLength={MAX_LENGTH_NAME}
                                    required
                                    fieldError={errors.contact?.firstName}
                                    {...register('contact.firstName', { required: true })}
                                    disabled={!!contact?.firstName || firstNameValue === nameMask}
                                />
                                <TextInput
                                    dataTestId="createSubscriptionFormLastName"
                                    label={t('form.input.lastName.label')}
                                    maxLength={MAX_LENGTH_NAME}
                                    required
                                    fieldError={errors.contact?.lastName}
                                    {...register('contact.lastName', { required: true })}
                                    disabled={!!contact?.lastName || lastNameValue === nameMask}
                                />
                                <TextInput
                                    dataTestId="createSubscriptionFormEmail"
                                    label={t('form.input.email.label')}
                                    fieldError={errors.contact?.email}
                                    maxLength={MAX_LENGTH_EMAIL}
                                    required
                                    infoMessage={
                                        showEmailErrorInfo ? (
                                            <NotificationMessage
                                                type={EInfoType.ERROR}
                                                dataTestId="createSubscriptionFormEmailInfoMessage"
                                                showTitle={false}
                                                message={
                                                    <Trans
                                                        i18nKey="subscription.create.emailInfo"
                                                        values={{
                                                            mailAddress: buContent.customerService.email,
                                                            phoneNumber: buContent.customerService.phoneNumer,
                                                        }}
                                                        components={{
                                                            mailto: (
                                                                <LinkText
                                                                    to={`mailto:${buContent.customerService.email}`}
                                                                />
                                                            ),
                                                        }}
                                                    />
                                                }
                                            />
                                        ) : null
                                    }
                                    {...register('contact.email', { required: true })}
                                    onFocus={() => setIsEmailFieldBlurred(false)}
                                    onBlur={onBlur}
                                />
                            </>
                        )}
                        <DropdownInput
                            dataTestId="createSubscriptionFormDeliveryOption"
                            label={t('form.input.deliveryOption.label')}
                            options={deliveryOptions}
                            fieldError={errors.deliveryOption}
                            required
                            infoButton={<DeliveryOptionsInfo />}
                            customPlaceholderOption="form.input.deliveryOption.placeHolder"
                            disabled={!organisationId || selectedRadio === ESubscriptionFormType.NONPERSONAL}
                            {...register('deliveryOption', { required: true })}
                            infoMessage={
                                disabledDeliveryOption ? (
                                    <NotificationMessage
                                        dataTestId="createSubscriptionFormDeliveryOptionInfo"
                                        showTitle={false}
                                        message={
                                            <Trans
                                                i18nKey="subscription.create.deliveryOptionInfo"
                                                values={{
                                                    mailAddress: buContent.customerService.email,
                                                    phoneNumber: buContent.customerService.phoneNumer,
                                                }}
                                                components={{
                                                    mailto: (
                                                        <LinkText to={`mailto:${buContent.customerService.email}`} />
                                                    ),
                                                }}
                                            />
                                        }
                                    />
                                ) : null
                            }
                        />
                        <StyledButton dataTestId="createSubscriptionPageSend" type={ButtonType.SUBMIT}>
                            {t('form.button.sendOrder')}
                        </StyledButton>
                    </StyledForm>
                </div>
                <Aside>
                    <InfoBox
                        dataTestId="createSubscriptionPageInfoBox"
                        color="grey"
                        title={t('subscription.create.infoBox.title')}
                        icon={<InfoIcon />}
                        content={
                            <Trans
                                i18nKey="subscription.create.infoBox.description"
                                components={{
                                    span: <StyledSpan />,
                                }}
                            />
                        }
                    />
                    <CustomerServiceInfoBox />
                </Aside>
            </Content>
        </Page>
    );
}

export default CreateSubscriptionPage;
